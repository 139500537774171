import React, { Suspense, useEffect, useState } from "react";
import { OWN_SESSION_JWT_TOKEN } from "./Register";
import {
  Employee,
  fetchOverview,
  fetchProfile,
  fetchTimeEvents,
  fetchTimeEventsForMonth,
  Overview,
  TimeEventEntry,
  UpsertTimeEvent,
  upsertTimeEvents,
} from "../resources";
import dayjs, { Dayjs } from "dayjs";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import _, { iteratee, update } from "lodash";
import NfcIcon from "@mui/icons-material/Nfc";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import ModeVertIcon from "@mui/icons-material/MoreVert";
import { red } from "@mui/material/colors";
import { Calendar } from "./Calendar";
import { logout } from "./OAuth";
import DeleteIcon from "@mui/icons-material/Delete";
import plugin, { Duration } from "dayjs/plugin/duration";
import { HelpMsg } from "./HelpMsg";
import { ActiveTimer } from "./ActiveTimer";

export function Home() {
  return (
    <Suspense
      fallback={
        <div className="flex flex-col items-center justify-center">
          <CircularProgress />
          <p>Loading user details...</p>
        </div>
      }
    >
      <NavBar />

      <div className="flex flex-row pt-8 space-x-4">
        <div className="">
          <ActiveTimer />
        </div>
        <div className="flex-1 pb-2">
          <HomeContent />
        </div>
      </div>
    </Suspense>
  );
}

const createDateRange = () => {
  const now = dayjs();
  const monthStart = now.subtract(2, "months");
  const monthEnd = now.add(1, "month");
  let months: { date: Dayjs; isCurrent: boolean }[] = [];

  for (let i = monthStart; i.isBefore(monthEnd); i = i.add(1, "month")) {
    months.push({ date: i, isCurrent: i == now });
  }

  return months;
};

const formatMonth = (date: Dayjs) => {
  let options = { year: "numeric", month: "long" };
  return new Intl.DateTimeFormat("de-DE", options as any).format(date.toDate());
};

const truncateMonth = (date: Dayjs) => date.format("YYYY-MM");
const truncateMonthToDate = (date: Dayjs) => dayjs(truncateMonth(date));

const formatDurationFactions = (duration: Duration) =>
  `${Math.floor(duration.asHours())}h ${duration.minutes()}min`;

const formatExpectedActualDifference = (
  expected: Duration,
  actual: Duration
) => {
  const diff = actual.subtract(expected);
  if (diff.asSeconds() < 0) {
    return `${formatDurationFactions(diff)} (Minusstunden)`;
  }
  return `${formatDurationFactions(diff)} (Überstunden)`;
};

const truncateDay = (date: Dayjs) => date.format("YYYY-MM-DD");

export const colorForKind = {
  Work: "bg-cyan-500",
  Holiday: "bg-green-600",
  Break: "bg-slate-500",
  Sick: "bg-red-500",
  OvertimeReduction: "bg-orange-300",
  School: "bg-red-100",
};
export const nameForKind = {
  Work: "Arbeit",
  Holiday: "Urlaub",
  Break: "Pause",
  Sick: "Krank",
  OvertimeReduction: "Überstundenabb.",
  School: "Unterricht",
};

export function NavBar() {
  const [profile, setProfile] = useState<Employee | null>(null);

  useEffect(() => {
    fetchProfile().then(setProfile);
  }, []);

  return (
    <nav className="bg-gray-800 p-4 shadow-sm">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <div className="text-white font-bold text-lg">æ</div>
        </div>
        <div className="flex items-end space-x-4">
          <img src={profile?.picture} alt="" className="w-8 h-8 rounded-full" />
          <div className="text-white">{profile?.name}</div>
          <button
            onClick={() => logout()}
            className="text-gray-300 hover:text-white transition duration-300"
          >
            Abmelden
          </button>
        </div>
      </div>
    </nav>
  );
}

export function HomeContent() {
  const [triggerRefresh, setTriggerRefresh] = useState<boolean>(true);
  const [timeEvents, setTimeEvents] = useState<TimeEventEntry[] | null>(null);
  const [overview, setOverview] = useState<Overview | null>(null);
  const [currentMonth, setCurrentMonth] = useState<Dayjs>(dayjs());

  const [currentEditDay, setCurrentEditDay] = useState<Dayjs | null>(null);
  const [currentEditEvents, setCurrentEditEvents] = useState<
    UpsertTimeEvent[] | null
  >(null);
  const [currentHolidayForm, setCurrentHolidayForm] =
    useState<UpsertTimeEvent | null>(null);

  useEffect(() => {
    fetchOverview().then(setOverview);
    fetchTimeEventsForMonth(truncateMonthToDate(currentMonth)).then(
      setTimeEvents
    );
  }, [currentMonth, triggerRefresh]);

  const timeEventsOnDay = (day: Dayjs) =>
    (timeEvents || []).filter((it) => truncateDay(it.date) == truncateDay(day));

  const openForm = (date) => {
    const events = timeEventsOnDay(date).filter((it) =>
      ["Work", "Break", "OvertimeReduction"].includes(it.kind)
    );
    setCurrentEditEvents(events);
    setCurrentEditDay(date);
  };

  const openHolidayForm = (timeEvent: UpsertTimeEvent | null) => {
    setCurrentHolidayForm(
      timeEvent ?? createInitialHolidayFromDate(currentMonth)
    );
  };

  const handleSave = async (
    events: UpsertTimeEvent[],
    eventsToDelete: string[]
  ) => {
    const res = await upsertTimeEvents({
      events_to_upsert: events,
      events_to_delete: eventsToDelete,
    });
    setCurrentEditDay(null);
    setTimeout(
      () =>
        fetchTimeEvents.reload().then(() => setTriggerRefresh(!triggerRefresh)),
      0
    );
  };

  const handleHolidaySave = async (
    events: UpsertTimeEvent[],
    eventsToDelete: string[]
  ) => {
    await upsertTimeEvents({
      events_to_upsert: events,
      events_to_delete: eventsToDelete,
    });
    setCurrentHolidayForm(null);
    setTimeout(
      () =>
        fetchTimeEvents.reload().then(() => setTriggerRefresh(!triggerRefresh)),
      0
    );
  };

  const handleTimeEventClick =
    (timeEvent: UpsertTimeEvent) => (ev: React.UIEvent) => {
      console.log(timeEvent);
      if (timeEvent.kind == "Sick" || timeEvent.kind == "Holiday") {
        console.log("prev");
        ev.stopPropagation();
        openHolidayForm(timeEvent);
      }
      return true;
    };

  if (overview == null) {
    return (
      <div className="w-full h-full flex flex-1 items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="relative min-h-full">
      <div className="container">
        {currentEditDay && (
          <TimeEventForm
            open={true}
            day={currentEditDay}
            handleClose={() => setCurrentEditDay(null)}
            handleSave={handleSave}
            initial={currentEditEvents ?? []}
          />
        )}
        {currentHolidayForm && (
          <HolidayForm
            open={true}
            initialEvent={currentHolidayForm}
            handleClose={() => setCurrentHolidayForm(null)}
            handleSave={handleHolidaySave}
          />
        )}
        <div className="flex flex-row space-x-4 ">
          <div className="flex flex-col border-2 space-y-2 py-2 rounded-md">
            <div className="px-4">
              Insgesamt
              <HelpMsg msg="Zählt die Stunden seit Arbeitsbeginn / Registierung (inklusive eingetragene Stunden in der Zukunft)" />
            </div>
            <div className="px-4 flex flex-row items-baseline">
              <div className="font-semibold">
                {formatDurationFactions(overview.actual_total)}
              </div>
              <div className="text-sm">
                {"/"} {formatDurationFactions(overview.expected_total)}
              </div>
            </div>
            <div className="px-4 border-t-2 my-2">
              {formatExpectedActualDifference(
                overview.expected_total,
                overview.actual_total
              )}
            </div>
          </div>
          <div className="flex flex-col border-2 space-y-2 py-2 rounded-md">
            <div className="px-4 ">
              Diese Woche{" "}
              <HelpMsg msg="Zählt nur Stunden, die diese Woche eingetragen wurden. Vom Soll-Wert sind bereits Krankheits- und Urlaubstage abgezogen" />
            </div>
            <div className="px-4 flex flex-row items-baseline">
              <div className="font-semibold">
                {formatDurationFactions(overview.actual_this_week)}
              </div>
              <div className="text-sm">
                {"/"} {formatDurationFactions(overview.expected_this_week)}
              </div>
            </div>
            <div className="px-4 border-t-2 my-2">
              {formatExpectedActualDifference(
                overview.expected_this_week,
                overview.actual_this_week
              )}
            </div>
          </div>
          <div className="flex flex-col border-2  space-y-2 py-2 rounded-md">
            <div className="px-4">
              Diesen Monat{" "}
              <HelpMsg msg="Zählt nur Stunden, die diesen Monat eingetragen wurden. Vom Soll-Wert sind bereits Krankheits- und Urlaubstage abgezogen" />
            </div>
            <div className="px-4 flex flex-row items-baseline">
              <div className="font-semibold">
                {formatDurationFactions(overview.actual_this_month)}
              </div>
              <div className="text-sm">
                {"/"} {formatDurationFactions(overview.expected_this_month)}
              </div>
            </div>
            <div className="px-4 border-t-2 my-2">
              {formatExpectedActualDifference(
                overview.expected_this_month,
                overview.actual_this_month
              )}
            </div>
          </div>
          <div className="flex flex-1"></div>
          <div className="flex">
            <Button onClick={() => openHolidayForm(null)}>
              Abwesenheit eintragen
            </Button>
          </div>
        </div>
        <Calendar onMonthChange={(it) => setCurrentMonth(it)}>
          {(day) => {
            const isToday = truncateDay(day) == truncateDay(dayjs());
            return (
              <>
                <div
                  onClick={() => openForm(day)}
                  key={day.format()}
                  className={`
                cursor-pointer
                flex-1 flex flex-col 
                border border-slate-200
                bg-slate-100
                ${isToday && "border-t-blue-500 border-t-4"}
                hover:bg-slate-200 group transition`}
                >
                  <div className="self-center text-sm text-slate-600">
                    {isToday && "Heute, "}
                    {day.format("D")}
                  </div>
                  <div className="flex flex-col gap-2 mt-2 mb-2 text-slate-100 text-sm max-h-80 overflow-y-auto">
                    {timeEventsOnDay(day).map(
                      (timeEvent) => (
                        <TimeEventPill
                          onClick={handleTimeEventClick(timeEvent)}
                          timeEvent={timeEvent}
                          day={day}
                        />
                      )
                      // <div onClick={handleTimeEventClick(timeEvent)} className={`rounded-md py-1 px-2 ${colorForKind[timeEvent.kind]!!} flex flex-row`}>
                      //   <div className="flex-1">{nameForKind[timeEvent.kind]!!}</div>
                      //   <div className="text-slate-700">{timeEvent.start.format("HH:mm")} - {timeEvent.end.format("HH:mm")}</div>
                      // </div>
                    )}
                    <button className="opacity-0 rounded-md mx-2 transition bg-slate-400 group-hover:opacity-100 text-slate-500 text-sm">
                      +
                    </button>
                  </div>
                </div>
              </>
            );
          }}
        </Calendar>
      </div>
      <div className="fixed bottom-8 right-8">
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => openForm(currentMonth)}
        >
          <AddIcon />
        </Fab>
      </div>
    </div>
  );
}

interface TimeEventPillProps {
  onClick: (ev: React.UIEvent) => void;
  timeEvent: TimeEventEntry;
  day: Dayjs;
}
export function TimeEventPill({ timeEvent, onClick, day }: TimeEventPillProps) {
  const startsOnSameDay = day.startOf("day").isBefore(timeEvent.start);
  const endsOnSameDay = timeEvent.end.isBefore(day.endOf("day"));

  const roundedLeft = startsOnSameDay ? "rounded-l-md" : "";
  const roundedRight = endsOnSameDay ? "rounded-r-md" : "";
  return (
    <div
      onClick={onClick}
      className={`${roundedLeft} ${roundedRight} py-1 px-2 ${colorForKind[
        timeEvent.kind
      ]!!} flex flex-row`}
    >
      <div className="flex-1">{nameForKind[timeEvent.kind]!!}</div>
      <div className="text-slate-700">
        {timeEvent.start.format("HH:mm")} - {timeEvent.end.format("HH:mm")}
      </div>
    </div>
  );
}

interface TimeEventFormProps {
  open: boolean;
  handleClose: () => void;
  handleSave: (upsert: UpsertTimeEvent[], toDelete: string[]) => void;
  day: Dayjs;
  initial: UpsertTimeEvent[];
}
export function TimeEventForm({
  open,
  handleClose,
  handleSave,
  day,
  initial,
}: TimeEventFormProps) {
  const [events, setEvents] = useState<UpsertTimeEvent[]>(initial);
  const [eventsToDelete, setEventsToDelete] = useState<string[]>([]);

  const logEvents = (events: UpsertTimeEvent[]) => {
    console.log(
      "new events",
      events.map(
        ({ eventUuid, preliminaryUuid, start, end }) =>
          `${eventUuid} ${preliminaryUuid} ${start.format(
            "HH:mm"
          )} - ${end.format("HH:mm")}`
      )
    );
  };

  const onSave = () => {
    logEvents(events);

    handleSave(events, eventsToDelete);
  };

  const deleteEvent = (i: number, eventUuid: string | null) => {
    if (eventUuid != null) {
      setEventsToDelete([...eventsToDelete, eventUuid]);
    }
    console.log("eventsToDelete", eventsToDelete);
    const newEvents = events.filter((it, index) => index != i);
    logEvents(newEvents);
    setEvents(newEvents);
  };

  const addRow = (kind) => () => {
    const newEvents = [
      ...events,
      {
        start: day.clone().set("h", 9).set("m", 0),
        end: day.clone().set("h", 17).set("m", 0),
        kind,
        eventUuid: null,
        preliminaryUuid: crypto.randomUUID(),
      },
    ];
    logEvents(newEvents);
    setEvents(newEvents);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Stunden Eintragen {day.format("DD.MM.YYYY")}</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Stack
            spacing={2}
            direction="column"
            divider={<Divider orientation="horizontal" flexItem />}
            alignItems="center"
          >
            {events.map((event, i) => (
              <TimeEventRow
                key={`${event.eventUuid}-${event.preliminaryUuid}`}
                event={event}
                onChange={(newEvent) => {
                  const newEvents = events.map((event, idx) =>
                    i == idx ? newEvent : event
                  );
                  logEvents(newEvents);
                  setEvents(newEvents);
                }}
                onDelete={(eventUuid) => deleteEvent(i, eventUuid)}
              />
            ))}
            <ButtonGroup
              key="add"
              variant="text"
              aria-label="text button group"
            >
              <Button onClick={addRow("Work")} startIcon={<AddIcon />}>
                Arbeitszeit
              </Button>
              <Button onClick={addRow("Break")} startIcon={<AddIcon />}>
                Pause
              </Button>
            </ButtonGroup>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={onSave}>Speichern</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

interface EventSourceIconProps {
  source: string | undefined;
}

function EventSourceIcon({ source }: EventSourceIconProps) {
  if (source == "user") {
    return <PersonIcon fontSize="small" />;
  }
  if (source == "time_clock") {
    return <NfcIcon fontSize="small" />;
  }
  return <></>;
}

interface KindSelectDropdownProps {
  event: UpsertTimeEvent;
  onChange: (event: UpsertTimeEvent) => void;
  onClose: () => void;
  anchorElement: HTMLElement | null;
}

function KindSelectDropdown({
  event,
  onChange,
  onClose,
  anchorElement,
}: KindSelectDropdownProps) {
  const open = Boolean(anchorElement);

  const onClickItem = (kind: string) => {
    onChange({ ...event, kind });
    onClose();
  };

  const options = [
    { name: "Überstundenabbau", kind: "OvertimeReduction" },
    { name: "Normale Pause", kind: "Break" },
  ];

  if (options.find((option) => option.kind == event.kind) == undefined) {
    return <></>;
  }

  return (
    <Menu anchorEl={anchorElement} open={open} onClose={onClose}>
      {options.map((option) => (
        <MenuItem
          key={option.kind}
          selected={event.kind == option.kind}
          onClick={() => onClickItem(option.kind)}
        >
          {option.name}
        </MenuItem>
      ))}
    </Menu>
  );
}

interface TimeEventRowProps {
  event: UpsertTimeEvent;
  onChange: (event: UpsertTimeEvent) => void;
  onDelete: (eventUuid: string | null) => void;
}
export function TimeEventRow({ event, onChange, onDelete }: TimeEventRowProps) {
  const [anchorElement, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClickMore = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopupClose = () => {
    setAnchorEl(null);
  };

  const parseTime = (current: Dayjs, time: string) => {
    const parsed = dayjs(time, "HH:mm");
    const updated = current
      .set("hours", parsed.get("hours"))
      .set("minutes", parsed.get("minutes"));
    return updated;
  };

  const encodeTime = (day: Dayjs | null) => {
    return day?.format("HH:mm");
  };

  return (
    <div className="w-full">
      <div className="flex flex-row items-center">
        <div className="flex-1">{nameForKind[event.kind]}</div>
        <IconButton aria-label="more" onClick={() => onDelete(event.eventUuid)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
        <IconButton aria-label="delete" onClick={(ev) => handleClickMore(ev)}>
          <ModeVertIcon fontSize="small" />
        </IconButton>
      </div>
      <KindSelectDropdown
        event={event}
        onChange={onChange}
        onClose={handlePopupClose}
        anchorElement={anchorElement}
      />
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            defaultValue={encodeTime(event.start)}
            onChange={(e) =>
              onChange({
                ...event,
                start: parseTime(event.start, e.target.value),
              })
            }
            autoFocus
            margin="dense"
            id="name"
            label={
              <>
                Anfang <EventSourceIcon source={event.start_source} />
              </>
            }
            type="time"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            defaultValue={encodeTime(event.end)}
            onChange={(e) =>
              onChange({ ...event, end: parseTime(event.end, e.target.value) })
            }
            margin="dense"
            id="name"
            label={
              <>
                Ende <EventSourceIcon source={event.end_source} />
              </>
            }
            type="time"
            fullWidth
            variant="standard"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export function createInitialHolidayFromDate(date: Dayjs): UpsertTimeEvent {
  return {
    kind: "Holiday",
    start: dayjs(truncateDay(date)),
    end: dayjs(truncateDay(date)).add(1, "day"),
    eventUuid: null,
  };
}

interface HolidayFormProps {
  open: boolean;
  handleClose: () => void;
  handleSave: (upsert: UpsertTimeEvent[], toDelete: string[]) => void;
  initialEvent: UpsertTimeEvent;
}

export function HolidayForm({
  open,
  handleClose,
  handleSave,
  initialEvent,
}: HolidayFormProps) {
  const [event, setEvent] = useState<UpsertTimeEvent>(initialEvent);

  const setStart = (time: string) => {
    setEvent({ ...event, start: dayjs(time, "YYYY-MM-DD") });
  };

  const setEnd = (time: string) => {
    setEvent({ ...event, end: dayjs(time, "YYYY-MM-DD") });
  };

  const setKind = (kind: string) => {
    setEvent({ ...event, kind });
  };

  const onSave = () => {
    handleSave([event], []);
  };

  const onDelete = () => {
    handleSave([], event.eventUuid ? [event.eventUuid] : []);
  };

  // const kinds = ['Holiday', 'Sick']

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Abwesenheit Eintragen</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Stack
            className="pt-8"
            direction="column"
            divider={<Divider orientation="horizontal" flexItem />}
            alignItems="center"
          >
            <FormControl fullWidth>
              <InputLabel id="form-type-of-holiday">Abwesenheitsart</InputLabel>
              <Select
                labelId="form-type-of-holiday"
                value={event.kind}
                label="Abwesenheitsart"
                onChange={(ev) => setKind(ev.target.value as any)}
              >
                <MenuItem value="Holiday">Urlaub</MenuItem>
                <MenuItem value="Sick">Krank</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            divider={<Divider orientation="horizontal" flexItem />}
            alignItems="center"
          >
            <TextField
              defaultValue={event.start.format("YYYY-MM-DD")}
              onChange={(e) => setStart(e.target.value)}
              autoFocus
              margin="dense"
              id="name"
              label="Anfang"
              type="date"
              fullWidth
              variant="standard"
            />
          </Stack>
          <Stack
            direction="column"
            divider={<Divider orientation="horizontal" flexItem />}
            alignItems="center"
          >
            <TextField
              defaultValue={event.end.format("YYYY-MM-DD")}
              onChange={(e) => setEnd(e.target.value)}
              autoFocus
              margin="dense"
              id="name"
              label="Ende"
              type="date"
              fullWidth
              variant="standard"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDelete}>Löschen</Button>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={onSave}>Speichern</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
