import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { fetchCurrentInOffice } from "../resources";


export function InOffice() {
  const [currentInOffice, setCurrentInOffice] = useState<any[]>([])
  useEffect(() => {
    fetchCurrentInOffice().then(setCurrentInOffice)

    const interval = setInterval(() => fetchCurrentInOffice().then(setCurrentInOffice), 10000);

    () => {
      console.log("clear")
      clearInterval(interval)
    }
  }, [])



  return <>
    <Typography variant="h6">Jetzt Im Büro</Typography>
    <List sx={{ width: '100%' }}>
    {currentInOffice.map(person => {
      const slackLink = `slack://user?team=&id=${person.id}`;
      return <ListItem key={person.id} disablePadding>
          <ListItemButton component="a" href={slackLink}>
            <ListItemAvatar>
              <Avatar alt={person.name} src={person.picture} />
            </ListItemAvatar>
            <ListItemText primary={person.name}/>
          </ListItemButton>
        </ListItem>
    })}
    </List>
    </>
}