import { Button, FormControl, FormHelperText, FormLabel, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { oauthSessionStorage, ownSessionStorage } from "../persistent-storage";

export const OWN_SESSION_JWT_TOKEN = "de.csicar.own.jwt.token"


export function Register() {
  const response = oauthSessionStorage.get()!;
  // const response = { NewUser: { slack_response: {} } }
  const [workHours, setWorkHours] = useState<number[]>([8, 8, 8, 8, 8, 0, 0]);
  const [holidays, setHolidays] = useState<string>(null!);
  const [error, setError] = useState<string | null>(null);
  const [employmentType, setEmploymentType] = useState<string | null>(null);
  const navigate = useNavigate();


  const setWorkHoursForDay = (i: number, value: string) => {
    const newWorkHours = workHours.slice();
    newWorkHours[i] = +value;
    setWorkHours(newWorkHours);
  }

  const onSubmit = () => {
    fetch("/api/register", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        hours_by_dow: workHours,
        holidays: +holidays,
        id_token: response.NewUser.slack_response.id_token,
        employment_type: employmentType
      })
    }).then(async (res) => {
      const content = await res.text();
      try {
        const d = JSON.parse(content)
        console.log(workHours, holidays, d)
        ownSessionStorage.set(d)
        navigate('/home')
      } catch (ex) {
        setError(ex)
      }
    })
  }
  return <>
    <div className="flex flex-col my-8">
      <div className={`
      flex flex-col 
      bg-gradient-to-b from-green-100 to-blue-200
      rounded-sm shadow-2xl

      items-center
      place-self-center gap-4 p-4 
      `}>
        <h1 className="text-lg">Registieren</h1>
        <img className="rounded-full aspect-square place-self-center max-w-xs" src={response.NewUser.picture}></img>
        <div>{response.NewUser.name}</div>
        <div>{response.NewUser.email}</div>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="input_holidays">Urlaubstage</InputLabel>
          <OutlinedInput
            id="input_holidays"
            label="Urlaubstage"
            onChange={(ev) => setHolidays(ev.target.value)}
            endAdornment={<InputAdornment position="end">Tage</InputAdornment>}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="register-employment-type">Anstellungsart</InputLabel>
          <Select
            labelId="register-employment-type"
            value={employmentType}
            label="Anstellungsart"
            onChange={(ev) => setEmploymentType(ev.target.value)}
          >
            <MenuItem value="Employee">Mitarbeiter</MenuItem>
            <MenuItem value="Azubi">Auszubildender</MenuItem>
          </Select>
        </FormControl>


        <FormLabel component="legend">Arbeitsstunden pro Tag</FormLabel>
        <div className="flex-row space-x-2">
          {['Mo', 'Di', 'Mi', 'Do', 'Fr'].map((dow, i) => {
            return <FormControl variant="outlined">
              <InputLabel htmlFor={`input-work-hours-${dow}`}>{dow}</InputLabel>
              <OutlinedInput
                id={`input-work-hours-${dow}`}
                label="mm"
                value={workHours[i]}
                onChange={(ev) => setWorkHoursForDay(i, ev.target.value)}
                endAdornment={<InputAdornment position="end">h</InputAdornment>}
                inputProps={{size: 2}}
              />
            </FormControl>
          })}
        </div>

        {error && <div className="text-red-700 font-bold">Failed to register</div>}
        <Button onClick={onSubmit} variant="contained">Speichern</Button>

      </div>
    </div>
  </>
}