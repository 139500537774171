import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./components/App";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { OAuthAuthenticated, OAuthCallback, OAuthLogin } from "./components/OAuth";
import { Register } from "./components/Register";
import { Home } from "./components/Home";
import customParseFormat from "dayjs/plugin/customParseFormat"
import utc from "dayjs/plugin/utc"
import relativeTime from "dayjs/plugin/relativeTime"
import duration from "dayjs/plugin/duration"
import localeDe from "dayjs/locale/de"
import { SnackbarProvider } from 'notistack';

import dayjs from "dayjs";
import Admin from "./components/Admin";
import AdminLogin from "./components/AdminLogin";
import AdminEmployee from "./components/AdminEmployee";
dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.locale(localeDe)


const router = createBrowserRouter([
  {
    path: "/oauth/login",
    element: <OAuthLogin />
  },
  {
    path: "/oauth/callback",
    element: <OAuthCallback />
  },
  {
    path: "/oauth/authenticated",
    element: <OAuthAuthenticated />
  },
  {
    path: "/register",
    element: <Register />
  },
  {
    path: "/home",
    element: <Home />
  },
  {
    path: "/admin",
    element: <Admin />
  },
  {
    path: "/admin/login",
    element: <AdminLogin />
  },
  {
    path: "/admin/employee/:employeeUuid",
    element: <AdminEmployee />
  },
  {
    path: "/",
    element: <App />
  }
], { basename: "/app" })

const container = document.getElementById("app");
const root = createRoot(container!)
root.render(<SnackbarProvider maxSnack={5}>
  <RouterProvider router={router} />
</SnackbarProvider>);