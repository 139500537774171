import dayjs, { Dayjs } from "dayjs";
import { OWN_SESSION_JWT_TOKEN } from "./components/Register";
import { mkResource, wrapPromise } from "./wrapPromise";

import utc from "dayjs/plugin/utc"
import relativeTime from "dayjs/plugin/relativeTime"
import { ownAdminStorage, ownSessionStorage } from "./persistent-storage";
import plugin from "dayjs/plugin/duration";
import { SSE } from "sse.js"

dayjs.extend(utc)
dayjs.extend(relativeTime)

async function fetchOrRedirect(url: URL | RequestInfo, options: RequestInit): Promise<Response> {
  const res = await fetch(url, options);
  console.debug("res", res)
  if (res.status == 401) {
    console.info("Got 401, redirecting")
    // document.location.pathname = `/app/oauth/login`
    return null!
  } else {
    return res
  }
}

const getAuth = () => ownSessionStorage.get()

export interface Employee {
  id: string;
  name: string;
  email: string;
  slack_id: string;
  picture: string;
}

export const fetchProfile: () => Promise<Employee> = async () => {
  const res = await fetchOrRedirect("/api/me", {
    headers: {
      Authorization: `Bearer ${getAuth()?.token}`
    }
  })
  const data = await res.json()
  return data
}

export const fetchTimeEvents = mkResource(async () => {
  const res = await fetchOrRedirect("/api/me/time_events", {
    headers: {
      Authorization: `Bearer ${getAuth()?.token}`
    }
  })
  const data = await res.json()
  return data
})

const parseDuration = (data) => dayjs.duration(data.seconds, "seconds")

export interface Overview {
  expected_total: plugin.Duration,
  actual_total: plugin.Duration,
  expected_this_month: plugin.Duration,
  actual_this_month: plugin.Duration,
  expected_this_week: plugin.Duration,
  actual_this_week: plugin.Duration,
}

export const fetchOverview: () => Promise<Overview> = async () => {
  const res = await fetchOrRedirect("/api/me/overview", {
    headers: {
      Authorization: `Bearer ${getAuth()?.token}`
    }
  })
  const data = await res.json()

  return {
    expected_total: parseDuration(data.expected_total),
    actual_total: parseDuration(data.actual_total),
    expected_this_month: parseDuration(data.expected_this_month),
    actual_this_month: parseDuration(data.actual_this_month),
    expected_this_week: parseDuration(data.expected_this_week),
    actual_this_week: parseDuration(data.actual_this_week),
  }
}


export interface TimeEventEntry {
  date: Dayjs;
  eventUuid: string;
  kind: string;
  duration: Dayjs;
  start: Dayjs;
  start_source: string;
  end: Dayjs;
  end_source: string;
}


export const fetchTimeEventsForMonth = async (month: Dayjs): Promise<TimeEventEntry[]> => {
  const res = await fetchOrRedirect(`/api/me/time_events/${month.format("YYYY-MM-DD")}`, {
    headers: {
      Authorization: `Bearer ${getAuth()?.token}`
    }
  })
  const data = await res.json()

  return data.map(it => ({
    date: dayjs(it.date),
    eventUuid: it.time_event_id,
    kind: it.kind,
    duration: dayjs.duration(it.duration),
    start: dayjs(it.start_time),
    start_source: it.start_source,
    end: dayjs(it.end_time),
    end_source: it.end_source,
  }))
}

export interface UpsertTimeEvent {
  start: Dayjs,
  end: Dayjs,
  kind: string,
  eventUuid: string | null,
  preliminaryUuid?: string,
  start_source?: string,
  end_source?: string
}

const timeEventToJson = ({ start, end, kind, eventUuid }: UpsertTimeEvent) => {
  return {
    start_time: start.utc().format(),
    end_time: end.utc().format(),
    kind,
    eventUuid,
  }
}

export interface UpsertTimeEventsRequest {
  events_to_upsert: UpsertTimeEvent[],
  events_to_delete: string[],
}
export const upsertTimeEvents = async (req: UpsertTimeEventsRequest) => {
  const res = await fetchOrRedirect("/api/me/time_events", {
    headers: {
      Authorization: `Bearer ${getAuth()?.token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    method: "PUT",
    body: JSON.stringify({
      events_to_upsert: req.events_to_upsert.map(timeEventToJson),
      events_to_delete: req.events_to_delete
    })
  })
  if (res.status >= 200 && res.status <= 299) {

    const data = await res.json()
    return data
  } else {
    throw new Error(await res.text())
  }
}

export interface FetchCurrentActiveTimerResponse {
  id: string;
  employee_id: string;
  start_time: Dayjs;
  start_source: string;
}
export const fetchCurrentActiveTimer = async () => {
  const res = await fetchOrRedirect("/api/me/timer", {
    headers: {
      Authorization: `Bearer ${getAuth()?.token}`,
      'Accept': 'application/json',
    }
  })
  if (res.ok) {

    const data = await res.json()
    if (data == null) { return null };
    return { ...data, start_time: dayjs(data.start_time) };
  } else {
    throw new Error(await res.text())
  }
}

export const subscribeUserEvents = (cb) => {
  const sse = new SSE("/api/me/events", {
    headers: {
      Authorization: `Bearer ${getAuth()?.token}`,
    }
  })
  sse.addEventListener("message", (e) => {
    fetchCurrentActiveTimer().then(cb)
  })
  return () => {
    sse.close()
  }
}

export const stopCurrentActiveTimer = async () => {
  const res = await fetchOrRedirect("/api/me/timer/stop", {
    headers: {
      Authorization: `Bearer ${getAuth()?.token}`,
    }, method: "POST"
  });
  if (res.status >= 200 && res.status <= 299) {
    return await res.json()
  } else {
    throw new Error(await res.text())
  }
}

export const fetchCurrentInOffice = async (token?: string) => {
  const credentials = token ?? ownAdminStorage.get()?.token
  const res = await fetchOrRedirect("/api/admin/in_office", {
    headers: {
      Authorization: `Bearer ${credentials}`
    }
  })
  const data = await res.json()
  return data
}

export const fetchEmployees = async (token?: string) => {
  const credentials = token ?? ownAdminStorage.get()?.token
  const res = await fetch("/api/admin/employees", {
    headers: {
      Authorization: `Bearer ${credentials}`
    }
  })
  const data = await res.json()
  return data
}


export const fetchUnknownChips = async (token?: string) => {
  const credentials = token ?? ownAdminStorage.get()?.token
  const res = await fetch("/api/admin/unknown_chips", {
    headers: {
      Authorization: `Bearer ${credentials}`
    }
  })
  const data = await res.json()
  return data
}

export const addChip = async (employeeUuid: string, chipUuid: string) => {
  const res = await fetch(`/api/admin/employee/${employeeUuid}/chip`, {
    headers: {
      Authorization: `Bearer ${ownAdminStorage.get()?.token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    method: "POST",
    body: JSON.stringify(chipUuid)
  })
  const data = await res.json()
  return data
}
export const postImpersonate = async (employeeUuid: string) => {
  const res = await fetch(`/api/admin/impersonate/${employeeUuid}`, {
    headers: {
      Authorization: `Bearer ${ownAdminStorage.get()?.token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    method: "POST",
  })
  const data = await res.json()
  return data
}