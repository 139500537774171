import dayjs, { Dayjs } from "dayjs";
import React, { Children, useState } from "react";
import { TimeEventForm } from "./Home";


export function CalendarTest() {
  return <Calendar onMonthChange={() => {}}>{day => 
    <div className="group flex-1 flex flex-col border border-slate-200 bg-slate-100 hover:bg-slate-200 transition">
            <div className="self-center font-semibold text-sm">{day.format("D")}</div>
            <div className="flex flex-col gap-2 mt-2 mb-2 text-slate-100 text-sm">
              
              {day.day() > 5 && <div className="rounded-md py-1 px-2 bg-green-600  ">Urlaub</div> }
              <div className="rounded-md py-1 px-2 bg-cyan-500  flex flex-row"><div className="flex-1">Arbeit</div><div className="text-slate-700">11:00 - 17:00</div></div>
              <div className="rounded-md py-1 px-2 bg-slate-500 text-slate-100 ">Pause</div>
              <div className="rounded-md py-1 px-2 bg-cyan-500 ">Arbeit</div>
              <button className="opacity-0 rounded-md mx-2 transition bg-slate-400 group-hover:opacity-100 text-slate-500 text-sm">+</button>
            </div>
    </div>
    
  }</Calendar>
}

interface CalendarProps {
  children: (day: Dayjs) => JSX.Element,
  onMonthChange: (newMonth: Dayjs) => void,
}
export function Calendar({ children, onMonthChange }: CalendarProps) {
  const [startOfMonth, setStartOfMonth] = useState(dayjs().date(1).hour(0).minute(0).second(0));

  const dayOfWeek = (startOfMonth.day() + 7) % 7;
  console.log(dayOfWeek, startOfMonth.day())

  const daysInMonth = startOfMonth.daysInMonth()
  const days = Array.from(Array(daysInMonth)).map((_, i) => {
    const currentDay = startOfMonth.date(i + 1);
    return currentDay
  })
  const style = {
    "gridColumnStart": 0,
    "gridColumnEnd": dayOfWeek
  }

  const switchMonth = (amount) => () => {
    const newMonth = startOfMonth.add(amount, "months")
    setStartOfMonth(newMonth)
    onMonthChange(newMonth)
  }

  const weekdays = ["Montag", 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
  return <>
    <div className="flex flex-row text-xl px-4 pt-4 mb-4 items-center border-b border-slate-400">
      <button onClick={switchMonth(-1)} style={{ lineHeight: 0 }} className="rounded-full p-4 aspect-square text-center font-bold hover:bg-slate-200">&lt;</button>
      <button onClick={switchMonth(1)} style={{ lineHeight: 0 }} className="rounded-full p-4 aspect-square  text-center font-bold hover:bg-slate-200">&gt;</button>
      <div className="p-4">{startOfMonth.format("MMMM YYYY")}</div>
    </div>
    <div className="grid grid-cols-7 auto-rows-fr ">
      {weekdays.map(it => <div key={it} className="text-center">{it}</div>)}
    </div>
    <div className="grid grid-cols-7 auto-rows-fr">
      {dayOfWeek > 1 ? <div key='pad' className="bg-transparent" style={style}></div> : <></>}
      {days.map(day =>
        <div className="flex flex-col basis-auto" key={day.format()}>
          {children(day)}
          
        </div>
      )}
    </div>
  </>

}