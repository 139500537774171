import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, Grid, TextField } from "@mui/material";
import { OWN_SESSION_JWT_TOKEN } from "./Register";
import { oauthIntermediateStorage as oauthIntermediateStorage, oauthSessionStorage as oauthSessionStorage, ownSessionStorage } from "../persistent-storage";

const oauthConfig = {
  redirectUrl: "https://slack.com/openid/connect/authorize"
}


function generateAuthorizationUrl(clientId: string, redirectUrl: string): URL {
  const state = crypto.randomUUID()
  const nonce = crypto.randomUUID()
  oauthIntermediateStorage.set({state, nonce})
  const params = new URLSearchParams({
    "response_type": "code",
    "scope": "openid profile email",
    "client_id": clientId,
    "state": state,
    "nonce": nonce,
    "redirect_uri": redirectUrl
  });
  let url = new URL(oauthConfig.redirectUrl)
  url.search = params.toString()
  return url
}

// const CLIENT_ID = "658414869781.4664260110327"
const CLIENT_ID = "1042950791591.4818187764673"
export function OAuthLogin() {
  useEffect(() => {
    const url = generateAuthorizationUrl(CLIENT_ID, "https://timetracking.egghead.de/app/oauth/callback")
    window.location.href = url.toString()
  }, [])
  return <>logging in..</>
}

export function OAuthCallback() {
  const [params, _] = useSearchParams();
  const navigate = useNavigate();
  const code = params.get("code")

  useEffect(() => {
    fetch("/api/oauth/token", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({code})
    }).then(async (res) => {
      const data = await res.json();
      console.log(data);
      if (data.NewUser) {
        oauthSessionStorage.set(data)
        navigate("/register")
      } else {
        ownSessionStorage.set(data.AlreadyRegistered)
        navigate("/home")
      }
    })
  }, [params]);

  return <>lädt..</>
}

export function OAuthAuthenticated() {
  const [params, _] = useSearchParams();
  const [userInfo, setUserInfo] = useState<null | any>();
  useEffect(() => {

    const p = Object.fromEntries(params)
    const { state, jwt, token } = p
    console.log(params)
    const expectedState = oauthSessionStorage.get()?.state
    if (state != expectedState) {
      // return;
    }
    setUserInfo(p)
    oauthSessionStorage.set({ state, jwt, token })
  }, [params])

  if (userInfo == null) { return <>...</> }
  return <>
    <div className="container mx-auto mt-16">
      <div className="flex flex-row justify-center">

        <div className="flex flex-col border-cyan-400 border-1 p-8 rounded-md space-y-4 bg-gradient-to-r from-cyan-200 to-blue-100">
          <h1 className="font-bold text-xl">Registieren</h1>
          <img className="border-1 border-cyan-800 rounded-full aspect-square w-64" src={userInfo.picture} />
          <div>{userInfo.name}</div>
          <div>{userInfo.mail}</div>
          <div>
            <>Wochenstunden:</>
            <input className="ml-4 w-40 border-slate-200 placeholder-slate-400" placeholder="40h" />
          </div>

          <button className="rounded-full bg-cyan-600 text-white p-4 hover:bg-cyan-400 transition">Speichern</button>
        </div>
      </div>
    </div>
  </>
}

export function logout() {
  oauthSessionStorage.clear()
  oauthIntermediateStorage.clear()
  ownSessionStorage.clear()
  window.location.reload()
}