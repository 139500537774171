import { Tooltip } from "@mui/material"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from "react"


export interface HelpMsgProps {
  msg: string
}
export function HelpMsg({msg}: HelpMsgProps) {

  return <Tooltip title={msg}><InfoOutlinedIcon className="text-slate-600" fontSize="small"/></ Tooltip>
}