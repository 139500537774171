import { Avatar, Button, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { FetchCurrentActiveTimerResponse, fetchCurrentActiveTimer, stopCurrentActiveTimer } from "../resources";
import { useSnackbar } from "notistack";
import HourglassIcon from '@mui/icons-material/HourglassBottom';
import { Dayjs } from "dayjs";



export function ActiveTimer() {
  const [currentActiveTimer, setCurrentActiveTimer] = useState<FetchCurrentActiveTimerResponse | null>(null)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    fetchCurrentActiveTimer().then(setCurrentActiveTimer)

    const interval = setInterval(() => fetchCurrentActiveTimer().then(setCurrentActiveTimer), 10000);
    () => {
      console.log("clear")
      clearInterval(interval)
    }
  }, [])

  const stopTimer = () => {
    stopCurrentActiveTimer().then((res) => { 
      enqueueSnackbar(`Arbeitszeit beendet: ${res.duration}`)
      setCurrentActiveTimer(null)
    })
  }

  const displayTime = (time: Dayjs) => 
    new Intl.DateTimeFormat('de-DE', { dateStyle: 'short', timeStyle: "short"} as any).format(time.toDate())
  ;

  return <Stack className="pl-2" spacing={2} direction="column">
    <Typography variant="h6"><HourglassIcon/> Stechuhr</Typography>
    {currentActiveTimer ?
      <>
        <div>
          Eintrag läuft seit {displayTime(currentActiveTimer.start_time)}
        </div>
        <Stack spacing={2} direction="row">
        <Button onClick={stopTimer} variant="contained">Stoppen</Button>
        </Stack>
      </>
      : <>Stechuhr ist nicht aktiv</>
    }
    </Stack>
}