import { Container, Dialog, DialogTitle, IconButton, List, ListItem, ListItemButton, Paper } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ownSessionStorage } from "../persistent-storage";
import { addChip, fetchEmployees, fetchUnknownChips, postImpersonate } from "../resources";
import NfcIcon from '@mui/icons-material/Nfc';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { useSnackbar } from 'notistack';
import { InOffice } from './InOffice'


export default function Admin() {
  const [employees, setEmployees] = useState<any[]>([]);
  const [chips, setChips] = useState<any[]>([]);
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  const refreshUnknownChips = () =>
    fetchUnknownChips().then(setChips)

  useEffect(() => {
    fetchEmployees().then(setEmployees)
    refreshUnknownChips()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      refreshUnknownChips()
    }, 3000);
    return () => clearInterval(interval);
  }, []);


  const impersonate = (employee) => () => {
    postImpersonate(employee.id).then((data: string) => {
      ownSessionStorage.set({ token: data, employee: null })
      console.log(data)
      navigate(`/admin/employee/${employee.id}`)
    })
  }

  const assignChip = (employee) => () => {
    console.log("asd")
    addChip(employee.id, chips[0].uid).then(() => {
      refreshUnknownChips()
      enqueueSnackbar(`Chip wurde ${employee.name} zugewiesen`)
    })
  }
  return <div className="flex flex-row relative min-h-full">
    <div className=" w-2/12 p-4 mr-8">
      <InOffice />
    </div>
    <div className="w-8/12 self-center container">
      <div className="text-lg">Admin</div>
      <Paper className="p-4 my-4" variant="outlined">
        Mitarbeiter
        <List>
          {employees.map(employee =>
            <ListItem disablePadding key={employee.id} secondaryAction={
              <IconButton edge="end" aria-label="assign" onClick={assignChip(employee)}>
                <UnfoldLessIcon />
              </IconButton>
            }>
              <ListItemButton onClick={impersonate(employee)}>
                {employee.name}
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Paper>
      <Paper className="p-4 my-4" variant="outlined">
        Unbekannte Chips

        <List component="nav">
          {chips.map((chip, i) =>
            <ListItem disablePadding key={chip.id} selected={i == 0}>
              <ListItemButton className="flex flex-row space-x-4 py-2">
                <NfcIcon />
                <div>{chip.uid}</div>
                <div className="flex-1"></div>
                <div> {dayjs(chip.time).fromNow()}</div>
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Paper>
    </div>
  </div>
}

interface DialogAssignChipProps {
  chipUuid: string,
  open: boolean,
  onClose: () => void,
  onSave: (employeeUuid: string) => void
}
function DialogAssignChip({ chipUuid, open }: DialogAssignChipProps) {
  return <Dialog open={open}>
    <DialogTitle>Chip Zuweisen</DialogTitle>
  </Dialog>
}