import React from "react";
import { useParams } from "react-router-dom";
import { fetchOverview } from "../resources";
import { Home } from "./Home";


export default function AdminEmployee() {
  const {employeeUuid} = useParams()
  console.log("rendering")

  return <>
    <Home></Home>
  </>
}