
const SESSION_STORAGE_AUTH_KEY = "de.csicar.oauth.intermediate"
const SESSION_STORAGE_TOKEN_KEY = "de.csicar.oauth.token"
const OWN_SESSION_JWT_TOKEN = "de.csicar.own.jwt.token"
const OWN_SESSION_ADMIN = "de.csicar.own.admin"

class PersistentStorage<T> {
  constructor(private storageKey : string) {}

  set(value : T) {
    console.debug(`Setting ${this.storageKey} to ${value}`)
    return window.localStorage.setItem(this.storageKey, JSON.stringify(value))
  }

  get() : T | null {
    try {
      const str = window.localStorage.getItem(this.storageKey)
      if (str) {
        return JSON.parse(str) 
      } else {
        throw new Error(`unexpected json ${str} while reading from ${this.storageKey}`)
      }
    } catch(e : any){
      console.error(e)
      return null
    }
  }

  clear() {
    console.debug(`Clear ${this.storageKey}`)
    window.localStorage.removeItem(this.storageKey)
  }
} 

export interface OauthIntermediate {
  state: string,
  nonce: string,
}
export const oauthIntermediateStorage = new PersistentStorage<OauthIntermediate>(SESSION_STORAGE_AUTH_KEY);

export interface OauthSession {
  state: string,
  jwt: string,
  token: string,
}
export const oauthSessionStorage = new PersistentStorage<OauthSession>(SESSION_STORAGE_TOKEN_KEY)



export interface OwnSession {
  token: string,
  employee: any,
}

export const ownSessionStorage = new PersistentStorage<OwnSession>(OWN_SESSION_JWT_TOKEN);


export interface OwnAdmin {
  token: string,
}

export const ownAdminStorage = new PersistentStorage<OwnAdmin>(OWN_SESSION_ADMIN);