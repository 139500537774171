type Resource<T> = { read: () => T }

export function wrapPromise<T>(promise: Promise<T>): Resource<T> {
  let status = 'pending'
  let response: T

  const suspender = promise.then(
    (res) => {
      status = 'success'
      response = res
    },
    (err) => {
      status = 'error'
      response = err
    },
  )
  const read = () => {
    switch (status) {
      case 'pending':
        throw suspender
      case 'error':
        throw response
      default:
        return response
    }
  }

  return { read }
}

type ReloadableResource<T> = { read: () => T, reload: () => Promise<void> }


export function mkResource<T>(fetcher: () => Promise<T>) : ReloadableResource<T> {
  let status = 'pending'
  let response: T
  let currentPromise : Promise<T> | null = null

  const read = () => {
    switch (status) {
      case 'pending':
        throw currentPromise
      case 'error':
        throw response
      default:
        return response
    }
  }

  const reload = () => {
    currentPromise = currentPromise || fetcher()
    status = 'pending'
    return currentPromise.then(
      (res) => {
        currentPromise = null
        status = 'success'
        response = res
      },
      (err) => {
        status = 'error'
        response = err
      },
    )
  }

  reload()

  return { read, reload }
}