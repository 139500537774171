import { Box, Button, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ownAdminStorage } from "../persistent-storage";
import { fetchEmployees } from "../resources";
import { useSnackbar } from 'notistack';



export default function AdminLogin() {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const login = () => {
    fetchEmployees(password).then(() => {
      ownAdminStorage.set({ token: password })
      navigate('/admin')
    }).catch(() => {
      enqueueSnackbar("Falsche Anmeldedaten", {variant: 'error'})
    })
  }

  return <div className={`bg-slate-200 min-h-screen w-full flex flex-col pt-16`}>
    <div className="self-center max-w-lg p-8 bg-slate-50 border border-slate-4 00 rounded-lg">
      <div className="text-xl pb-4 text-center">Admin Login</div>

      <Stack spacing={2}>
        <TextField id="admin-username" label="Nutzername" variant="outlined"
          onChange={(ev) => setUsername(ev.target.value)}
        />
        <TextField
          id="admin-password"
          label="Password"
          type="password"
          autoComplete="current-password"
          onChange={(ev) => setPassword(ev.target.value)}
        />
        <Button variant="contained" onClick={() => login()}>Login</Button>
      </Stack>
    </div>
  </div>
}